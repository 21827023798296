<template>
  <td class="pet-info-cell" :class="{error: hasErrors}">
    <span>{{value}}</span>
    <imported-data-table-cell-error-list v-if="hasErrors" :errors="errors" />
  </td>
</template>
<script>
export default {
  name: 'pet-info-cell',
  props: ['pet', 'keyName'],
  computed: {
    value() {
      return this.pet[this.keyName];
    },
    errors() {
      return this.pet.errors.filter(e => e.key === this.keyName);
    },
    hasErrors() {
      return this.errors.length > 0;
    }
  },
}
</script>