
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
import helpers from './helpers.js';

window.helpers = helpers;

import * as moment from 'moment';
window.moment = moment;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('search-bar', require('./components/SearchBar/SearchBar.vue').default);
Vue.component('receipt-list', require('./components/ReceiptList/ReceiptList.vue').default);
Vue.component('client-editor', require('./components/ClientEditor/ClientEditor.vue').default);
Vue.component('client-pet-editor', require('./components/ClientPetEditor/ClientPetEditor.vue').default);
Vue.component('template-editor', require('./components/TemplateEditor/TemplateEditor.vue').default);
Vue.component('pets-list', require('./components/PetsList/PetsList.vue').default);

Vue.component('reminders-list', require('./components/ReminderList/ReminderList.vue').default);
Vue.component('impersonator', require('./components/Impersonator/Impersonator.vue').default);
Vue.component('analytics-dashboard', require('./components/AnalyticsDashboard/AnalyticsDashboard.vue').default);

Vue.component('imported-data-table-cell-error-list', require('./components/ImportedDataTable/ImportedDataTableCellErrorList.vue').default);
Vue.component('pet-info-cell', require('./components/ImportedDataTable/PetInfoCell.vue').default);
Vue.component('imported-data-table', require('./components/ImportedDataTable/ImportedDataTable.vue').default);
Vue.component('date-editor', require('./components/DateEditor.vue').default);


if (document.getElementById('app')) {
  const app = new Vue({
    el: '#app',
    mounted() {
      document.documentElement.classList.add('vue-loaded');
      // helpers.init();
    }
  });
} else {
  // helpers.init();
}
if (document.getElementById('searchBar')) {
  const searchBar = new Vue({
    el: '#searchBar',
    mounted() {
      // document.documentElement.classList.add('vue-loaded');
      // helpers.init();
    }
  });
} else {
  // helpers.init();
}
