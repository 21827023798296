import AnnualReminder from "../../models/annualReminder.models";
import Client from '../../models/client.models';
import Pet from '../../models/pet.models';
import Product from '../../models/product.models';

export default {
  props: ['clientData', 'productsData', 'mode', 'currentVet', 'editUrl'],
  data: () => ({
    client: null,
    products: [],
    isSaving: false,
    hasPermissionNoticeBeenRead: false,
    petsToDelete: [],
    area: window.location.href.includes('myreminders') ? 'front' : 'vet',
  }),
  computed: {
    isVet() {
      return this.area === 'vet';
    },
    isClient() {
      return this.area === 'front';
    },
    clientSectionTitle() {
      return this.isClient ? 'Your Information' : 'Client (Pet Owner) Information';
    },
    petSectionTitle() {
      return this.isClient ? 'Your Pets and Reminders' : 'Pets and Reminders';
    },
    consentingMessage() {
      return this.isClient ? (
        'I have given the permission to receive reminder messages and I have been shown the Privacy Notice.'
      ) : (
          'The client has given permission to receive reminder messages and has been shown the Privacy Notice.'
        );
    },
    optoutMessage() {
      return this.isClient ? (
        'Opt out of all reminder notifications.'
      ) : (
          'The client has opted out of all reminder notifications.'
        );
    }
  },
  beforeMount() {
    this.products = this.productsData ? this.productsData.map(p => new Product(p)) : [];
    switch (this.mode) {
      case 'edit': {
        this.client = new Client(this.clientData);
        break;
      }
      case 'new':
      default: {
        this.client = new Client();
        this.addPet();
        break;
      }
    }
  },
  methods: {
    createNewAnnualReminder(pet) {
      pet.use_annual_reminder = true;
      pet.annual_reminder = new AnnualReminder(void 0);
    },
    sendDeletePetRequest(pet) {
      if (!pet.id) {
        this.client.pets.splice(this.client.pets.indexOf(pet), 1);
        return;
      }
      window.axios.defaults.headers.common = {
        'X-CSRF-TOKEN': window.Laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest'
      };
      return window.axios.delete('/client/pet/' + pet.id)
        .then(response => {
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    },

    addPet() {
      const newPet = new Pet();
      this.client.pets.push(newPet);
    },

    deletePet(pet) {
      // this.petsToDelete.push(pet);
      this.client.pets.splice(this.client.pets.indexOf(pet), 1);
    },

    undoDeletePet(pet) {
      const i = this.petsToDelete.indexOf(pet);
      if (i >= 0) {
        this.petsToDelete.splice(i, 1);
      }
    },

    async submitForm() {
      if (this.client.is_consenting == false && this.hasPermissionNoticeBeenRead == false) {
        alert('Please note you have not opted the client in to receive messages. Please accept that the client has been shown the privacy notice to opt into this service.');
        this.hasPermissionNoticeBeenRead = true;
        return;
      }

      this.isSaving = true;

      if (this.petsToDelete.length > 0) {
        // const promises = this.petsToDelete.map(pet => this.sendDeletePetRequest(pet));
        // await Promise.all(promises);
      }

      const url = '/' + this.currentVet.id + '/clients';

      this.client.pets.forEach(pet => {
        pet.reminder_records.forEach(r => {
          r.application_id = pet.application_id;
          r.product_id = pet.product_id
        })
      })

      let payload = JSON.parse(JSON.stringify(this.client));
      // console.log(payload);
      // debugger;

      switch (this.mode) {
        case 'edit': {
          const finalUrl = (this.editUrl ? '/' + this.editUrl : url) + '/' + payload.id;
          // console.log(finalUrl);
          axios.put(finalUrl, payload)
            .then(async response => {
              this.isSaving = false;
              if (this.isClient) {
                await helpers.sessionNotices.notify('success', 'Your changes have been saved');
              }
              window.location.reload();
            })
            .catch(error => this.onSubmitError(error));
          break;
        }
        default: {
          axios.post(url, payload)
            .then(response => this.onCreationSuccess(response))
            .catch(error => this.onSubmitError(error));
          break;
        }
      }
    },

    onCreationSuccess(response) {
      this.isSaving = false;
      helpers.sessionNotices.notify('success', 'Client created!');
      if (this.isVet) {
        window.location.href = '/clients/' + response.data.id + '/edit';
      }
    },

    onSubmitError(error) {
      this.isSaving = false;
      let body = `Failed saving client. ${error.response.data.message || ''} <br />`;
      if (error.response) {
        if (error.response.data.errors) {
          for (let key in error.response.data.errors) {
            body += '- ' + error.response.data.errors[key] + '<br />';
          }
        }
      }
      helpers.sessionNotices.notify('error', body);
    }
  }
}
