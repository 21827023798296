import moment from "moment";

export default class VetAdministeredVaccination {
    constructor (data) {
        this.id = data ? data.id : null;
        this.pet_id = data ? data.pet_id : '';

        this.treated_at = data ? (data.treated_at && moment(data.treated_at).format('YYYY-MM-DD')) : '';
        this.created_at = data ? (data.created_at && moment(data.created_at).format('YYYY-MM-DD')) : '';
        this.updated_at = data ? (data.updated_at && moment(data.updated_at).format('YYYY-MM-DD')) : '';
    }
}
