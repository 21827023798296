import * as moment from 'moment';
import Pet from '../../models/pet.models';

export default {
  props: ['data'],
  data() {
    return {
      pets: [],
      // reminders: [],
    }
  },
  beforeMount() {
    this.processPetsAndReminders();
  },
  methods: {
    moment: moment,
    processPetsAndReminders() {
      this.pets = this.data ? (this.data.pets || []).map(p => new Pet(p)) : [];
      // this.reminders = this.data ? (this.data.reminders || []).map(r => new Reminder(r)) : [];
      // this.reminders.forEach(r => {
      //   this.pets.forEach(p => {
      //     if (r.pet_id === p.id) {
      //       p.reminders.push(r);
      //     }
      //   })
      // })
    }
  }
}
