export function toKebabString(input) {
  return (input || '').toString().replace(/[A-Z0-9]/g, $1 => "-" + $1.toLowerCase()).replace(/\s/g, '').replace(/^-*|-*$/, '');
}
export function toSnakeString(input) {
  return (input || '').toString().replace(/[A-Z0-9]/g, $1 => "_" + $1.toLowerCase());
}
export function toCamelString(input) {
  return (input || '').toString().replace(/(?:-|_)[a-zA-z0-9]/g, $1 => $1.substr(1).toUpperCase());
}
export function toSentenceCase(input) {
  return toKebabString((input || '').toString()).replace(/[_-]/g, ' ');
}
export function toUpperCase(input) {
  return toSentenceCase(input || '').toLocaleUpperCase();
}
export function toTitleCase(input) {
  return toSentenceCase((input || '').toString()).split(' ').map(t => (t[0] || '').toUpperCase() + t.substr(1)).join(' ');
}
export function normalizeString(input) {
  return (input || '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
export function capitalizeSingleWord(input) {
  return input ? input[0].toUpperCase() + input.substr(1) : input;
}
export function capitalize(input) {
  return input.split(' ').map(s => capitalizeSingleWord(s)).join(' ');
}


export function isPlural(input) {
  return /[isz]es|[a-rt-z]s$/.test(input);
}
export function pluralize(input) {
  if (!input)
    return '';
  if (isPlural(input))
    return input;
  switch (input.substr(input.length - 1)) {
    case 's': return input + 'es';
    case 'y': return input.substr(0, input.length - 1) + 'ies';
    case 'z': return input + 'zes';
    default: return input + 's';
  }
}

export function isSingular(input) {
  return !isPlural(input);
}
export function singularize(input) {
  if (!input) return '';
  if (isSingular(input)) return input;
  if (input.match(/ies$/)) {
    return input.replace(/ies$/, 'y');
  }
  if (input.match(/ses$/)) {
    return input.replace(/ses$/, 'se');
  }
  if (input.match(/zzes$/)) {
    return input.replace(/zzes$/, 'z');
  }
  return input.replace(/s$/, '');
}

export function autoPluralize(
  amount,
  singularString,
  zeroString,
  pluralString,
  doNotAutoDisplayNumber) {
  if (!amount && zeroString) return zeroString;
  if (amount === 1) {
    if (singularString.includes('%d')) {
      return singularString.replace(/%d/, '1');
    }
    if (doNotAutoDisplayNumber) return singularString;
    return '1 ' + singularString;
  } else {
    const _pluralString = pluralString || pluralize(singularString);
    if (_pluralString) {
      if (_pluralString.includes('%d')) {
        return _pluralString.replace(/%d/, amount + '');
      }
      if (doNotAutoDisplayNumber) return _pluralString;
      return amount + ' ' + _pluralString;
    }
  }
  return amount;
}