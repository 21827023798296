import helpers from '../helpers';
import Product from './product.models';
import Application from './application.models';
import * as moment from 'moment';


export default class AnnualReminder {
  constructor(data) {
    this.id = data ? data.id : null;
    this.message = data ? data.message : '';
    // this.scheduled_for = data ? data.scheduled_for : '';
    this.petId = data ? (data.petId || data.pet_id) : '';
    this.scheduled_for = moment(data ? (data.scheduled_for || data.scheduled_for) : moment().add(1, 'year')).format('YYYY-MM-DD');
  }
}
