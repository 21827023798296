export default {
  data() {
    return {
      keyword: '',
      init: false,
      isLoading: false,
      hasFocus: false,
      results: {
        clients: {
          title: 'clients',
          data: [],
        },
        pets: {
          title: 'pets',
          data: [],
        },
        users: {
          title: 'users',
          data: [],
        },
        vets: {
          title: 'vets',
          data: [],
        },
      },
    }
  },
  computed: {
    noResults() {
      return this.results.clients.data.length === 0 &&
        this.results.pets.data.length === 0 &&
        this.results.users.data.length === 0 &&
        this.results.vets.data.length === 0;
    },
    availableResults() {
    }
  },
  methods: {
    onSearchInput: _.debounce(function() {
      this.instantSearch();
    }, 500),
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      setTimeout(() => {
        this.hasFocus = false;
      },200)
    },
    instantSearch() {
      this.isLoading = true;
      axios.get('/search?query=' + this.keyword).then(
        response => {
          const data = response.data;
          this.results.clients.data = (data.clients && data.clients.data || []).map(e => e);
          this.results.pets.data = (data.pets && data.pets.data || []).map(e => e);
          this.results.users.data = (data.users && data.users.data || []).map(e => e);
          this.results.vets.data = (data.vets && data.vets.data || []).map(e => e);
          this.isLoading = false;
          this.init = true;
          this.$forceUpdate();
        }
      )
    }
  }
}
