import { render, staticRenderFns } from "./ImportedDataTable.vue?vue&type=template&id=b39bb1c6&"
import script from "./ImportedDataTable.js?vue&type=script&lang=js&"
export * from "./ImportedDataTable.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports