import {TweenLite, Power3} from 'gsap';
import 'gsap/CSSPlugin';
import * as moment from 'moment';

const helpers = {
  addClass(selector, classToRemove) {
    const els = document.querySelectorAll(selector);
    Array.prototype.forEach.call(els, el => {
      el.classList.add(classToRemove);
    })
  },
  removeClass(selector, classToRemove) {
    const els = document.querySelectorAll(selector);
    Array.prototype.forEach.call(els, el => {
      el.classList.remove(classToRemove);
    })
  },
  toggleUserMenu() {
    const el = document.getElementById('user-profile-container');
    if (el.classList.contains('open')) el.classList.remove('open');
    else el.classList.add('open');
  },
  formatAsPhpDate(date) {
    return moment(date).format('YYYY-MM-DD');
  },
  sessionNotices: {
    notify(type, message) {
      const noticeItemEl = document.getElementById('session-notice-item');
      if (noticeItemEl) noticeItemEl.setAttribute('data-type', type);
      const noticeBodyEl = document.getElementById('session-notice-body');
      if (noticeBodyEl) noticeBodyEl.innerHTML = message;
      const noticeIconEl = document.getElementById('session-notice-icon-use');
      if (noticeIconEl) noticeIconEl.setAttribute('xlink:href', type === 'success' ? '#icon-check' : '#icon-warning');
      const noticeSectionEl = document.getElementById('session-notice-section');
      return new Promise(async (resolve, reject) => {
        try {
          if (noticeSectionEl) {
            noticeSectionEl.classList.add('show');
            TweenLite.set(noticeSectionEl, { height: 'auto' });
            TweenLite.from(noticeSectionEl, 0.38, { height: 0, ease: Power3.easeOut });
            resolve(true);
          }
        } catch(e) {
          console.error(e);
          await window.alert(message.replace(/<br\s*\/>/g, ''));
          resolve(true);
        }
      })
    },
    dismiss(e) {
      const noticeSectionEl = document.querySelector(`.session-notice-section`);
      TweenLite.to(noticeSectionEl, 0.38, { height: 0, ease: Power3.easeOut }).eventCallback('onComplete', () => {
        noticeSectionEl.classList.remove('show');
      });
    }
  },
};

export default helpers;
