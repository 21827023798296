import Pet from '../../models/pet.models';
import Reminder from '../../models/reminder.models';
import * as moment from 'moment';

export default {
  data() {
    return {
      data: {
        'data': [],
        'links': {
          first: '',
          last: '',
          next: '',
          prev: '',
        },
        'meta': {
          current_page: '',
          from: '',
          last_page: '',
          path: '',
          per_page: '',
          to: '',
          total: '',
        },
      },
      'isLoading' : false
    }
  },

  mounted() {
    axios.get('/api/upcoming-reminders').then(response => {
      if (!response) return;
      let data = response.data;
      response.data.data.forEach(i => {
        i.formattedScheduleTime = moment(i.scheduled_at).format('YYYY-MM-DD');
      })
      this.data = data;
    })
  },

  methods: {
    nextPage() {
      this.getData(this.data.links.next);
    },

    prevPage() {
      this.getData(this.data.links.prev);
    },

      /**
       * send reminder test
       */
    testReminder(reminderId) {
        if (window.confirm('A test SMS message will be sent only to your personal phone number which is set on your profile.')) {
            axios.put('/admin/reminders/test/' + reminderId)
                .then(() => {})
                .catch(() => {});
        }
    },

    getData(url) {
      this.isLoading = true;
      this.hasError = false;
      url = url.replace(/^https?:\/\/[^/]*/, window.location.origin);
      axios.get(url).then(response => {
        if (!response) return;
        this.data = response.data
      }).catch(error => {
        console.error(error);
        this.hasError = true;
      });

      this.isLoading = false;
    }
  }

}
