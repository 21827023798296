export default {
  props: ['data'],
  data() {
    return {
      isSaving: false,
      hasError: false,
      form: Object.assign({},this.data),
      renderSamples: {
        '{user.name}': 'James',
        '{user.surname}': 'O\'Connor',
        '{user.fullname}': 'James O\'Connor',
        '{user.email}': 'james@sampleclient.com',
        '{user.number}': '0830000000',
        '{vet.name}': 'Nolan Finnegan',
        '{vet.email}': 'nolan@samplevet.com',
        '{vet.number}': '0830000001',
        '{client.name}': 'Lynn',
        '{client.surname}': 'McDermott',
        '{client.number}': '0830000002',
        '{client.email}': 'lynn@sampleclient.com',
        '{pet.name}': 'Sammy',
        '{pet.treated}': '27-09-2018 14:50PM',
        '{reminder.date}': '27-09-2018',
        '{pet.owner.name}': 'Lynn',
        '{pet.owner.surname}': 'McDermott',
        '{pet.owner.number}': '0830000002',
        '{pet.owner.email}': 'lynn@sampleclient.com',
        '{product.name}': 'BRAVECTO SPOT-ON FOR DOGS',
        '{product.logo}': '',
        '{application.name}': 'Flea & Tick',
        '{application.name.lowercase}': 'flea & tick',
        '{email.unsubscribe.url}': '/unsubscribe/00000000-0000-0000-0000-000000000000',
        '{annual.message}': 'This is an example of the message that will be set by Vet.',
        '{sms.service.number}': '353111222333',
      }
    }
  },
  computed: {
    renderedTemplate() {
      let value = this.form.body;
      if (!value) return '';
      this.hasError = false;
      let renderedHTML = value;
      let i = 0;
      const regex = /{[a-z.A-Z]+}/;
      while (renderedHTML.match(regex) && i < 100) {
        let varName = renderedHTML.match(regex);
        let dummyContent = this.renderSamples[varName];
        if (!dummyContent) {
          dummyContent = '<span class="error">Invalid Variable</span>';
          this.hasError = true;
        }
        renderedHTML = renderedHTML.replace(varName,dummyContent);
        i++;
      }
      return renderedHTML;
    }
  },
  methods: {
    submitForm() {
      const url = '/admin/notification-templates/' + this.form.id + '/update';
      const payload = JSON.parse(JSON.stringify(this.form));
      axios.post(url, payload).then(
        response => this.onSubmitSuccess(response),
      ).catch(
        error => this.onSubmitError(error)
      )
    },
    onSubmitSuccess(response) {
      this.isSaving = false;
    },
    onSubmitError(error) {
      this.isSaving = false;
      console.error(error)
    }
  }
}
