<template>
<div class="impersonator-vue-component">

  <h2 class="sub-heading full-width">Impersonate</h2>

  <p>Login as if you are using the Vet account you are inspecting. This feature allows you <em>the admin</em> to login as if you were a Vet and see the site exactly the way the Vet will see when they log in.</p>
  <p>Simply find a Vet account from the selector below to switch to impersonate mode.</p>
  <br />
  <div class="impersonator-vet-searcher">
    <input v-model="query" placeholder="Search by Vet name..."/>
    <ul class="impersonator-vet-dropdown">
      <li v-if="filteredVets.length === 0"><em>No vets matching your query.</em></li>
      <li v-for="vet in filteredVets" v-if="vet.id != currentVet" ><a :href="'./impersonate/take/' + vet.user_id">Login as <strong>{{ vet.name }}</strong></a></li>
    </ul>
  </div>
  <br />

</div>
</template>

<script>
export default {
  props: ['data', 'currentVet'],
  data() {
    return {
      vets: [],
      query: '',
    }
  },
  beforeMount() {
    this.processVets();
  },
  computed: {
    filteredVets() {
      return this.query ? this.vets.filter(v => v.name.toLowerCase().replace(/\s/g, '').indexOf(this.query.toLowerCase().replace(/\s/g, '')) >= 0) : this.vets;
    }
  },
  methods: {
    processVets() {
      this.vets = this.data;
    },
  }
}
</script>

<style src="./Impersonator.scss" lang="scss"></style>
