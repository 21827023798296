<template>
  <input type="date" v-model="displayValue" @change="handleChange"/>
</template>
<script>
import moment from 'moment';
export default {
  name: 'date-editor',
  props: ['value', 'offsetDays'],
  data () {
    return {
    _internalValue: this.value,
    }
  },
  computed: {
    displayValue: {
      get() {
        return moment(this.value).add(this.offsetDays, 'days').format('YYYY-MM-DD');
      },
      set(value) {
        this._internalValue = moment(value).subtract(this.offsetDays, 'days').format('YYYY-MM-DD');
      }
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this._internalValue);
    },
  },
}
</script>