import { autoPluralize, toTitleCase } from "../../helpers/string";

export default {
    props: ["data", "columns", "errors", "url"],
    data() {
        var sortOrders = {};
        this.columns.forEach(function(key) {
            sortOrders[key] = 1;
        });
        return {
            sortKey: "",
            sortOrders: sortOrders,
            imported: false,
            // imported: true,
            importing: false,
            importResult: [],
            groupedData: []
            // importResult: JSON.parse(importResult).users,
        };
    },
    computed: {
        validRows() {
            return this.d.filter(r => r._is_valid && r.is_phone_valid);
        },
        duplicatedPhones() {
            return this.data
                .map(e => e.phone)
                .filter((p, i, arr) => arr.filter(x => x === p).length > 1);
        },
        uniquePhones() {
            return Array.from(new Set(this.data.map(e => e.phone)));
        },
        existingClientsSelected() {
            return this.groupedData.filter(
                e => e.phone_exists && e.is_selected
            );
        },
        selectedLength() {
            return this.groupedData.filter(d => d.is_selected).length;
        },
        validGroupedData() {
            return this.groupedData.filter(g => g.is_valid);
        },
        allSelected: {
            get() {
                return this.validGroupedData.every(g => g.is_selected);
            },
            set(value) {
                this.validGroupedData.forEach(g => (g.is_selected = value));
            }
        }
    },
    watch: {
        selectedLength() {
            return 1;
        }
    },
    mounted() {
        this.groupedData = this.uniquePhones.map(uniquePhone => {
            const records = this.data.filter(e => e.phone === uniquePhone);
            const owner = records[records.length - 1];
            const ownerRecordIndex = this.data.indexOf(owner);
            const {
                phone,
                email,
                first_name,
                surname,
                optout,
                phone_exists,
                is_phone_valid
            } = owner;
            const ownerErrors = Object.entries(this.errors)
                .filter(
                    e =>
                        new RegExp(`^${ownerRecordIndex}.`).test(e[0]) &&
                        e[0].indexOf("pet_") < 0
                )
                .map(e => this.formatRawError(e, ownerRecordIndex));
            const pets = records.map(p => {
                const {
                    pet_name,
                    parasite_product,
                    reminder_message,
                    bravecto_treatment_date,
                    vaccination_date
                } = p;
                return {
                    pet_name,
                    parasite_product,
                    reminder_message,
                    bravecto_treatment_date,
                    vaccination_date,
                    errors: (() => {
                        const petRecordIndex = this.data.indexOf(p);
                        const errors = Object.entries(this.errors)
                            .filter(e =>
                                new RegExp(`^${petRecordIndex}.`).test(e[0])
                            )
                            .map(e => this.formatRawError(e, petRecordIndex));
                        return errors;
                    })()
                };
            });
            const is_valid =
                (is_phone_valid && phone) ||
                (email && email.includes("@")) ||
                ((phone || email) &&
                    first_name &&
                    ownerErrors.length === 0 &&
                    pets.every(p => p.errors.length === 0));
            const is_selected = !phone_exists && is_phone_valid && is_valid;
            return {
                client: {
                    phone,
                    email,
                    first_name,
                    surname,
                    optout,
                    errors: ownerErrors
                },
                pets,
                phone_exists,
                is_phone_valid,
                is_valid,
                is_selected
            };
        });
    },
    filters: {
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    methods: {
        formatRawError(e, i) {
            const key = e[0].replace(new RegExp(`^${i}.`), "");
            const keyRegex = new RegExp(`(?:The)?\\s${e[0]}`);
            return {
                key,
                messages: e[1].map(message =>
                    message.replace(keyRegex, ` "${toTitleCase(key)}"`)
                )
            };
        },
        sendImportRequest() {
            this.importing = true;
            // const payload = this.selected.map(e => {
            //   const copy = { ...e };
            //   delete copy._is_valid;
            //   return copy;
            // });
            axios
                .post(this.url, this.groupedData)
                .then(response => {
                    // console.log(response);
                    if (response.data) {
                        this.imported = true;
                        this.importing = false;
                        this.selected = [];
                        this.importResult = response.data;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.importing = false;
                });
        },
        autoPluralize
    }
};
