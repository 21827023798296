import moment from "moment";
import Reminder from "../../models/reminder.models";
import Axios from "axios";

export default {
  name: 'client-pet-editor',
  props: ['client', 'pet', 'index', 'products', 'vet', 'toDelete', 'area'],
  data: () => ({
    localForm: {
      enableAnnualReminders: false,
    }
  }),
  beforeMount() {
    if (!this.pet.treated_at) {
      const today = moment().format('YYYY-MM-DD');
      this.pet.treated_at = today;
      // this.pet.renewal_vet_administration_date = moment(today).add(1, 'year').format('YYYY-MM-DD');
    } else {
      // this.pet.renewal_vet_administration_date = moment(this.pet.treated_at).add(1, 'year').format('YYYY-MM-DD');
    }
    if (this.annualReminders.length) {
      this.localForm.enableAnnualReminders = true;
    }
  },
  // mounted() {
  // },
  computed: {
    isVet() {
      return this.area === 'vet';
    },
    isClient() {
      return this.area === 'front';
    },
    clientHasExistingAnnualReminders() {
      if (this.isVet) return true;
      return (this.isClient && this.pet.vaccinated_at != null);
    },
    selectedProduct() {
      return this.products.find(p => p.id === this.pet.product_id);
    },
    selectedApplication() {
      return this.selectedProduct ? this.selectedProduct.applications.find(a => a.id === this.pet.application_id) : null;
    },
    periodicReminders() {
      return this.pet.reminder_records.filter(r => !r.is_annual_reminder);
    },
    annualReminders() {
      return this.pet.reminder_records.filter(r => r.is_annual_reminder);
    },
    periodicRemindersDelivered() {
      return this.periodicReminders.filter(r => r.is_delivered);
    },
    annualRemindersDelivered() {
      return this.annualReminders.filter(r => r.is_delivered);
    },
    treatmentDate() {
      return this.pet.treated_at;
    },
    treatmentDateIsValid() {
      return this.isValidDate(this.treatmentDate);
    },
    lastAnnualReminder() {
      return this.annualReminders.sort((a, b) => moment(b.scheduled_at).diff(a.scheduled_at))[0];
    },
    renewalDate: {
      get() {
        return this.lastAnnualReminder ? moment(this.lastAnnualReminder.scheduled_at).add(1, 'week').format('YYYY-MM-DD') : void 0;
      },
      set(value) {
        // console.log(value);
        if (value) {
          this.refreshAnnualReminders(value);
        } else {
          this.removeAnnualReminders();
        }
      }
    },
  },
  watch: {
    selectedProduct() {
      // console.log(this);
      if (this.selectedProduct && this.selectedApplication) {
        this.refreshPeriodicReminders();
      } else {
        this.removePeriodicReminders();
      }
    },
    selectedApplication() {
      // console.log(this);
      if (this.selectedApplication) {
        this.refreshPeriodicReminders();
      } else {
        this.removePeriodicReminders();
      }
    },
    treatmentDate() {
      this.refreshDates();
    },
  },
  filters: {
  },
  methods: {
    formatAsDDMMYYYY(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    isValidDate(value) {
      return moment(value).isValid();
    },
    getDiff(value, unit) {
      return this.pet && moment(value).diff(this.pet.treated_at, unit) || 0;
    },
    isInThePast(value) {
      return moment(value).diff() < 0;
    },
    handleProductChange() {
      this.pet.application_id = null;
    },
    refreshPeriodicReminders() {

      const firstTreatmentDate = this.pet.treated_at || moment().format('YYYY-MM-DD');
      const dates = Array(4).fill(null).map((n, i) => (i + 1) * 12 * 7 - 3).map(n => moment(firstTreatmentDate).add(n, 'days').format('YYYY-MM-DD'));

      if (this.isClient) {
        this.pet.reminder_records = this.pet.reminder_records.filter(r => !r.is_annual_reminder);
        this.pet.reminder_records.forEach((element, index) => {
          element.scheduled_at = dates[index]
        });
        return this.pet.reminder_records;
      }

      // elseif client allow for changing of product.
      this.removePeriodicReminders();
      if (!this.vet.enable_vaccination_reminders) return;
      if (!this.pet.product_id || !this.pet.application_id) return;
      const created_at = moment().format('YYYY-MM-DD');
      const reminder_records = dates.map(d => new Reminder({
        pet_id: this.pet.id,
        client_id: this.client.id,
        is_annual_reminder: false,
        is_active: true,
        is_delivered: false,
        scheduled_at: d,
        created_at,
        updated_at: created_at,
      }));

      this.pet.reminder_records.push(...reminder_records);

    },
    refreshAdministeredDateReminders(renewalDate) {
      renewalDate = moment(renewalDate).add(1, 'y');
      this.refreshAnnualReminders(renewalDate);
      this.pet.renewal_vet_administration_date = moment(renewalDate, "YYYY-MM-DD").subtract(1, 'y').format('YYYY-MM-DD');
      // console.log(this.pet.renewal_vet_administration_date);
    },
    refreshAnnualReminders(renewalDate) {
      this.removeAnnualReminders();
      if (!this.vet.enable_annual_reminders) return;
      const dates = [3, 1].map(n => moment(renewalDate).subtract(n, 'weeks'));
      const created_at = moment().format('YYYY-MM-DD');
      const reminder_records = dates.map(d => new Reminder({
        pet_id: this.pet.id,
        client_id: this.client.id,
        is_annual_reminder: true,
        is_active: true,
        is_delivered: false,
        scheduled_at: d,
        created_at,
        updated_at: created_at,
      }));
      this.pet.reminder_records.push(...reminder_records);
    },
    refreshDates() {
      this.refreshPeriodicReminders();
      if (this.localForm.enableAnnualReminders) {
        // this.refreshAnnualReminders(moment(this.treatmentDate).add(1, 'year'));
      }
    },
    handleAnnualReminderToggle() {
      const value = this.localForm.enableAnnualReminders;
      if (value) {
        this.refreshAnnualReminders(moment(this.treatmentDate).add(1, 'year'));
      } else {
        this.removeAnnualReminders();
      }
    },
    removePeriodicReminders() {
      this.pet.reminder_records = this.pet.reminder_records.filter(
        r => r.is_annual_reminder
      );
    },
    removeAnnualReminders() {
      this.pet.reminder_records = this.pet.reminder_records.filter(r => !r.is_annual_reminder);
    },
    async deletePet() {
      // this.pet.delete = true;
      const confirm = await window.confirm('Are you sure you want to delete this pet?');
      if (!confirm) return;
      if (!this.pet.id) {
        this.$emit('delete');
        return;
      }
      const url = `/client/pet/${this.pet.id}`;
      Axios.delete(url).then(() => {
        window.location.reload();
      }).catch(async e => {
        await window.alert('There was an error deleting the pet.');
        window.location.reload();
      })
    },
    undoDeletePet() {
      this.pet.delete = false;
      this.$emit('undo-delete');
    },
  }
}
