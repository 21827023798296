import * as moment from 'moment';

export default {
  data() {
    return {
      data: {
        chartRegistrations: {
          title: 'Account Registrations',
          registrations: [],
        },
        chartTotalAccounts: {
          title: 'Total Accounts',
          accounts: [],
        },
        chartClientAccounts: {
          title: 'Total Accounts',
          accounts: [],
        },
      },
    }
  },

  watch: {
  },

  mounted() {

    // Load the Visualization API and the corechart package.
    google.charts.load('current', { packages: ['corechart', 'line'] });

    axios
      .get('/api/data-analytics/registrations')
      .then(response => {
        var array = response.data;
        var formattedArray = array.map((currentElement, index) => {
          currentElement[0] = new Date(currentElement[0]);
          return currentElement;
        });
        this.data.chartRegistrations.registrations = formattedArray;
        // Set a callback to run when the Google Visualization API is loaded.
        google.charts.setOnLoadCallback(this.drawChartRegistrations);
      });

    axios
      .get('/api/data-analytics/total-accounts')
      .then(response => {
        var array = response.data;
        var formattedArray = array.map((currentElement, index) => {
          currentElement[0] = new Date(currentElement[0]);
          return currentElement;
        });
        this.data.chartTotalAccounts.accounts = formattedArray;
        // Set a callback to run when the Google Visualization API is loaded.
        google.charts.setOnLoadCallback(this.drawChartTotalAccounts);
      });

    axios
      .get('/api/data-analytics/client-accounts')
      .then(response => {
        var array = response.data;
        var formattedArray = array.map((currentElement, index) => {
          currentElement[0] = new Date(currentElement[0]);
          return currentElement;
        });
        this.data.chartClientAccounts.accounts = formattedArray;
        // Set a callback to run when the Google Visualization API is loaded.
        google.charts.setOnLoadCallback(this.drawChartClientAccounts);
      });
  },

  methods: {
    drawChartRegistrations() {
      var data = new google.visualization.DataTable();

      data.addColumn('date', 'Month');
      data.addColumn('number', 'Registrations');
      data.addRows(this.data.chartRegistrations.registrations);
      // data.addRows(this.data.chartRegistrations.registrations);

      // Set chart options
      var options = {
        title: 'Monthly Registrations',
        hAxis: {
          title: 'Months'
        },
        vAxis: {
          title: 'Registrations'
        },
        backgroundColor: '#fff',
        padding: 0,
        height: 300
      };

      // Instantiate and draw our chart, passing in some options.
      var chart = new google.visualization.LineChart(this.$refs.chart_one);
      chart.draw(data, options);
    },

    drawChartTotalAccounts() {
      var data = new google.visualization.DataTable();

      data.addColumn('date', 'Month');
      data.addColumn('number', 'Accounts');

      data.addRows(this.data.chartTotalAccounts.accounts);

      // Set chart options
      var options = {
        title: 'Total Accounts',
        hAxis: {
          title: 'Months'
        },
        vAxis: {
          title: 'Registrations'
        },
        series: {
          1: { curveType: 'function' }
        },
        padding: 0,
        height: 300

      };

      // Instantiate and draw our chart, passing in some options.
      var chart = new google.visualization.LineChart(this.$refs.chart_two);
      chart.draw(data, options);
    },

    drawChartClientAccounts() {
      var data = new google.visualization.DataTable();

      data.addColumn('date', 'Month');
      data.addColumn('number', 'Clients');
      data.addColumn('number', 'Pets');

      data.addRows(this.data.chartClientAccounts.accounts);

      // Set chart options
      var options = {
        title: 'Client vs Pet Ratio',
        hAxis: {
          title: 'Months'
        },
        vAxis: {
          title: 'Registrations'
        },
        series: {
          1: { curveType: 'function' }
        },
        padding: 0,
        height: 300
      };

      // Instantiate and draw our chart, passing in some options.
      var chart = new google.visualization.LineChart(this.$refs.chart_three);
      chart.draw(data, options);
    }
  }
}
