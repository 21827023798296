import moment from 'moment';
import Pet from './pet.models';

export default class Client {
  constructor (data) {
    this.id = data ? data.id : null,
    this.uuid = data ? data.uuid : null,
    this.first_name = data ? data.first_name : '',
    this.surname = data ? data.surname : '',
    this.email = data ? data.email : '',
    this.phone = data ? data.phone : '',
    this.is_consenting = data ? data.is_consenting : false,
    this.optout = data ? data.optout : false,
    this.accepts_email = data ? data.accepts_email : false,
    this.accepts_sms = data ? data.accepts_sms : false,
    this.address_id = data ? data.address_id : false,
    this.created_at = data ? (data.created_at && moment(data.created_at).format('YYYY-MM-DD')) : false,
    this.pets = (data && data.pets) ? (data.pets || []).map(p => new Pet(p)) : [];
  }
}
