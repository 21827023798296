import Reminder from './reminder.models';
import VetAdministeredVaccination from './vetAdministeredVaccination.models';
import moment from 'moment';

export default class Pet {
  constructor (data) {
    this.id = data ? data.id : null;
    this.name = data ? data.name : '';
    this.delete = false;
    this.client_id = data ? data.client_id : null;
    this.renewal_vet_administration_date = data ? data.renewal_vet_administration_date && moment(data.renewal_vet_administration_date).format('YYYY-MM-DD') : '';
    this.treated_at = data ? data.treated_at && moment(data.treated_at).format('YYYY-MM-DD') : '';
    this.vaccinated_at = data ? data.vaccinated_at && moment(data.vaccinated_at).format('YYYY-MM-DD') : '';
    this.created_at = data ? data.created_at && moment(data.created_at).format('YYYY-MM-DD') : '';
    this.reminder_records = data ? (data.reminder_records || []).map(r => new Reminder(r)) : [];
    this.vet_administered_vaccinations = data ? (data.vet_administered_vaccinations || []).map(r => new VetAdministeredVaccination(r)) : [];
    const firstRegularReminder = this.reminder_records.find(r => !r.is_annual_reminder);
    this.product_id = firstRegularReminder ? firstRegularReminder.product_id : '';
    this.application_id = firstRegularReminder ? firstRegularReminder.application_id : '';
  }
}
