import moment from "moment";

export default class Reminder {
  constructor (data) {
    this.id = data ? data.id : null;
    this.pet_id = data ? data.pet_id : '';
    this.client_id = data ? data.client_id : '';
    this.client_updated_at = data ? data.client_updated_at : '';

    this.product_id = data ? data.product_id : '';
    this.application_id = data ? data.application_id : '';

    this.is_annual_reminder = data ? !!data.is_annual_reminder : false;

    this.mail_template_id = data ? data.mail_template_id : '';
    this.sms_template_id = data ? data.sms_template_id : '';

    this.is_active = data ? !!data.is_active : true;
    this.is_delivered = data ? !!data.is_delivered : false;

    this.scheduled_at = data ? (data.scheduled_at && moment(data.scheduled_at).format('YYYY-MM-DD')) : '';
    this.created_at = data ? (data.created_at && moment(data.created_at).format('YYYY-MM-DD')) : '';
    this.updated_at = data ? (data.updated_at && moment(data.updated_at).format('YYYY-MM-DD')) : '';

  }
}
