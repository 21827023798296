<template>
  <ul v-if="errors.length > 0" class="client-import-table-cell__error-list">
    <li v-for="(error, i) in errors" :key="i">
      <p v-for="(message, j) in error.messages" :key="j"><em>- {{message}}</em></p>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'imported-data-table-cell-error-list',
  props: ['errors'],
}
</script>